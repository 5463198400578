const esJSON = {
  vouchers: {
    tableInputs: {
      'Provider': 'Proveedor',
      'SKU': 'SKU',
      'Denomination': 'Denominación',
      'Brand': 'Marca',
      'Actions': 'Acciones',
      'Code': 'Código',
      'Expiration Date': 'Fecha de vencimiento',
      'URL': 'URL',
      'Status': 'Estado',
      'Program ID': 'ID de Programa',
      'Delivery ID': 'ID de envío',
      'Application ID': 'ID de Aplicación',
      'Order Purchase': 'Orden de Compra',
      'Invoice': 'Factura',
    },
    formInputs:{
      'PROVIDER': 'PROVEEDOR',
      'SKU': 'SKU',
      'DENOMINATION': 'DENOMINACIÓN',
      'BRAND': 'MARCA',
      'CODE': 'CÓDIGO',
      'EXPIRATION DATE': 'FECHA DE VENCIMIENTO',
      'URL': 'URL',
      'STATUS': 'ESTADO',
      'PROGRAM ID': 'ID DE PROGRAMA',
      'DELIVERY ID': 'ID DE ENVÍO',
      'APPLICATION ID': 'ID DE APLICACIÓN',
      'ORDER PURCHASE': 'ORDEN DE COMPRA',
      'INVOICE': 'FACTURA',
    },
    placeholders: {
      'Provider': 'Proveedor',
      'Sku': 'Sku',
      'Denomination': 'Denominación',
      'Brand': 'Marca',
      'Actions': 'Acciones',
      'Code': 'Código',
      'Url': 'Url',
      'Status': 'Estado',
      'Program ID': 'ID de Programa',
      'Delivery ID': 'ID de envío',
      'Application ID': 'ID de Aplicación',
      'Order Purchase': 'Orden de Compra',
      'Invoice': 'Factura',
    },
    forms: {
      'This field is required': 'Este campo es obligatorio'
    },
    main: {
      'Download Excel Template': 'Descargar plantilla de Excel',
      'Upload Excel': 'Cargar excel',
      'Add New Voucher': 'Añadir nuevo bono',
      'Load Records': 'Registros de Carga',
      'No vouchers found for the selected filters': 'No se encontraron bonos para los filtros seleccionados',
      'No vouchers found': 'No se encontraron bonos',
      'edit': 'modificar',
      'delete': 'eliminar',
      'Do you really want to delete this voucher?': '¿Realmente desea eliminar este bono?',
      'You won\'t be able to reverse the voucher deletion': 'No podrás revertir la eliminación del bono',
      'Cancel': 'Cancelar',
      'Delete': 'Eliminar',
      'Edit Voucher': 'Modificar bono',
      'Use this modal dialog to update a voucher': 'Usar este cuadro de diálogo modal para actualizar un bono',
      'No vouchers were found to load in the archive.' : 'No se encontraron bonos para cargar en el archivo.',
      'Have been added': 'Se han agregado',
      'vouchers successfully': 'bonos exitosamente',
      'An error occurred when trying to add the voucher: ': 'Ocurrió un error al intentar agregar el bono: ',
      'The URL provided is invalid. It must be a valid URL.' : 'La URL proporcionada no es válida. Debe ser una dirección URL válida.',
      'You cannot modify an assigned voucher': 'No puedes modificar un bono asignado',
      'You cannot delete an assigned voucher': 'No puedes eliminar un bono asignado',
      'The selected file is not valid. Please select an .xlsx file.': 'El archivo seleccionado no es válido. Por favor, selecciona un archivo .xlsx',
      'Voucher successfully removed.': 'Bono eliminado exitosamente.',
      'There was an error while trying to load Excel': 'Hubo un error al intentar cargar el Excel',
      'download': 'descargar',
      'information': 'informacion',
      'Close': 'Cerrar'
    },
    new: {
      'Voucher successfully added': 'Bono agregado exitosamente',
      'Add new voucher': 'Agregar nuevo bono',
      'Complete the fields below to create and add a new voucher to the site': 'Complete los campos a continuación para crear y agregar un nuevo bono al sitio',
      'Cancel': 'Cancelar',
      'Create Voucher': 'Crear Bono'
    },
    update: {
      'Voucher successfully edited': 'Bono editado exitosamente',
      'Available': 'Disponible',
      'Assigned': 'Asignado',
      'Cancel': 'Cancelar',
      'Modify': 'Modificar',
      'Voucher update failed':'No pudimos actualizar el bono',
      'Voucher with the same delivery id already exists': 'Ya existe un bono con el id de entrega indicado',
      'A voucher with the sku and code already exists': 'Ya existe un bono con el sku y código indicados',
    },
    load:{
      'Error: Some vouchers already exist. Check the excel file before uploading. Remember that SKU and Code are unique.': 'Algunos comprobantes ya existen. Compruebe el archivo Excel antes de cargarlo. Recuerde que SKU y Código son únicos.'
    },
    filterForm: {
      'Clear all filters': 'Borrar todos los filtros',
      'Filters': 'Filtros',
      'filter': 'filtrar',
      'Add filters': 'Agregar filtros',
      'Complete the fields below to add filters': 'Complete los campos a continuación para añadir filtros',
      'Cancel': 'Cancelar',
      'Clean': 'Limpiar',
      'Apply Filters': 'Aplicar Filtros',
      'Select State': 'Seleccionar Estado',
      'Available': 'Disponible',
      'Assigned': 'Asignado',
    }
  },
  Blueprints: 'Planos',
  'Extended Sidebar': 'Barra lateral extendida',
  'Accent Sidebar': 'Barra lateral de acento',
  'Accent Header': 'Encabezado de acento',
  'Boxed Sidebar': 'Barra lateral en caja',
  'Collapsed Sidebar': 'Barra lateral contraída',
  'Bottom Navigation': 'Navegación inferior',
  'Top Navigation': 'Navegación superior',
  'Lead Developer': 'Desarrollador principal',
  'Mega menu': 'Mega menú',
  Doctors: 'Doctores',
  Reports: 'Informes',
  'Custom dashboard built using the included components':
    'Panel de control personalizado construido con los componentes incluidos',
  Hospital: 'Hospital',
  Export: 'Exportar',
  'Last year': 'El año pasado',
  Expenses: 'Gastos',
  Products: 'Productos',
  Statistics: 'Estadísticas',
  General: 'General',
  Automation: 'Automatización',
  'Data Display': 'Visualización de datos',
  Calendar: 'Calendario',
  Mailbox: 'Buzón',
  Dashboards: 'Cuadros de mando',
  Analytics: 'Analítica',
  Banking: 'Bancario',
  Commerce: 'Comercio',
  Crypto: 'Cripto',
  Finance: 'Finanzas',
  Fitness: 'Aptitud física',
  Healthcare: 'Cuidado de la salud',
  'Doctors Page': 'Página de médicos',
  'Hospital Overview': 'Descripción del hospital',
  Helpdesk: 'Mesa de ayuda',
  Learning: 'Aprendiendo',
  Monitoring: 'Supervisión',
  Tasks: 'Tareas',
  Vouchers: 'Bonos',
  Applications: 'Aplicaciones',
  'File Manager': 'Administrador de archivos',
  'Jobs Platform': 'Plataforma de trabajos',
  Messenger: 'Mensajero',
  'Projects Board': 'Junta de proyectos',
  'Voucher Management': 'Administración de Bonos',
  Users: 'Usuarios',
  'List All': 'Listar todo',
  'User Profile': 'Perfil del usuario',
  Projects: 'Proyectos',
  Shop: 'Tienda',
  'Products List': 'Lista de productos',
  'View Product': 'Ver el producto',
  'Create Product': 'Crear producto',
  Invoices: 'Facturas',
  'View Single': 'Ver individual',
  'Auth Pages': 'Autenticación',
  Login: 'Inicio de Sesión',
  Basic: 'Básico',
  Cover: 'Cubrir',
  Register: 'Registrarse',
  Wizard: 'Mago',
  'Recover Password': 'Recuperar contraseña',
  Status: 'Estado',
  Foundation: 'Fundación',
  'Extra Pages': 'Páginas extra',
  'Error 404': 'Error 404',
  'Error 500': 'Error 500',
  'Coming Soon': 'Próximamente',
  Maintenance: 'Mantenimiento',
  Overview: 'Visión general',
  'Layout Starters': 'Arrancadores de diseño',
  'Starter Kit 1': 'Kit de inicio 1',
  'Starter Kit 2': 'Kit de inicio 2',
  Documentation: 'Documentación',
  Welcome: 'Bienvenida',
  Help: 'Ayuda',
  'Features tour': 'Tour de características',
  'Getting started guide': 'Guía de inicio',
  'Contact support': 'Soporte de contacto',
  Version: 'Versión',
  Search: 'Buscar',
  Notifications: 'Notificaciones',
  Settings: 'Ajustes',
  'Language Switcher': 'Selector de idioma',
  'Sign out': 'Desconectar',
  'Change Theme': 'Cambiar de tema',
  'View all notifications': 'Ver todas las notificaciones',
  'Tokyo NextJS Typescript Admin Dashboard':
    'Panel de administración de Tokyo React',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos',
  'Browse Live Preview': 'Explorar vista previa en vivo',
  'Live Preview': 'Vista previa en vivo',
  'Key Features': 'Características clave',
  dashboards: 'cuadros de mando',
  Logout: 'Cerrar Sesión',
  'Sign in with Google': 'Iniciar sesión con Google',
  applications: 'aplicaciones',
  'management sections': 'secciones de gestión',
  'dark/light themes': 'temas oscuros / claros',
  components: 'componentes',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad',
  'Design Source Files': 'Diseñar archivos fuente',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero',
  'We only translated a small part of the template, for demonstration purposes':
    'Solo traducimos una pequeña parte de la plantilla, con fines de demostración'
};

export default esJSON;
