import axios from 'src/utils/axiosCreate';
import type { User } from 'src/models/user';
import { apiConfig } from 'src/api/apiConfig';

export const fetchUser = async (userId: number) => {
  const response = await axios.get<{ data: User }>(`/users/${userId}`);
  return response;
};

export const fetchUserByAuthID = async () => {
  const response = await apiConfig.get(`/api/auth`);
  return response;
};

// export const fetchUserByAuthID = async (uid: string) => {
//   const response = await axios.get<{ data: User }>(`/users/uid/${uid}`);
//   return response;
// };
