import { Box, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './logo.css'

function Logo() {
  const { t }: { t: any } = useTranslation();

  return (
    <Grid>
      <Box>
        <img 
          src={require('./logo.png')} 
          alt="GLUKY"
          className="logo-image"
        />
      </Box>
    </Grid>
  );
}

export default Logo;
