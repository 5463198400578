import type { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

export interface MenuItem {
  link?: string;
  icon?: OverridableComponent<SvgIconTypeMap> & {
    muiName: string;
  };
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Bonos',
        icon: CardGiftcardIcon,
        link: '/dashboard/management/bonos'
      }
    ]
  }/* ,
  {
    heading: 'Extra Pages',
    items: [
      {
        name: 'Status',
        icon: ErrorTwoToneIcon,
        link: '/status',
        items: [
          {
            name: 'Error 404',
            link: '/status/404'
          },
          {
            name: 'Error 500',
            link: '/status/500'
          },
          {
            name: 'Maintenance',
            link: '/status/maintenance'
          },
          {
            name: 'Coming Soon',
            link: '/status/coming-soon'
          }
        ]
      }
    ]
  } */
];

export default menuItems;
