import { Box, Grid, IconButton, Tooltip, Typography, styled, useTheme, alpha, lighten } from '@mui/material';

import HeaderMenu from './Menu';
import HeaderUserbox from './Userbox';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useContext } from 'react';
import Logo from 'src/components/Logo';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: 100;
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        /* backdrop-filter: blur(3px);
        position: fixed; */
        justify-content: space-between;
        width: 100%;
`
);

function Header() {

  const theme = useTheme();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="space-between"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid>
            <Tooltip arrow title="Menu">
                <IconButton color="primary" onClick={toggleSidebar}>
                    <MenuTwoToneIcon />
                </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
          <Grid>
            <Box style={{ marginBottom: 4, marginTop: 4 }}>
              <img 
                src={require('../../../components/Logo/logo.png')} 
                alt="GLUKY"
                className="logo-image"
                style={{ width: 150, height: 75}}
              />
            </Box>
          </Grid>
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center">
          <Box
            component="span"
          >
            <HeaderMenu />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <HeaderUserbox />
        </Box>
    </HeaderWrapper>
  );
}

export default Header;