import { createSlice } from '@reduxjs/toolkit';
import { Bonos } from 'src/models/bonos';

interface BonosState {
    bonosList: Bonos[];
    isLoading: boolean;
    pages: number;
    statusCode: number;
    isErrorMessage: string;
    selectedBono: null | Bonos
}

const initialState: BonosState = {
    bonosList: [],
    isLoading: false,
    pages: 1,
    statusCode: 201,
    isErrorMessage: '',
    selectedBono: null,
}

export const bonosSlice = createSlice({
    name: 'bonos',
    initialState,
    reducers: {
        startLoadingBonos: (state) => {
            state.isLoading = true;
        },
        setTotalPages: (state, action) => {
            state.isLoading = false;
            state.pages = action.payload;
        },
        setAllBonos: (state, action) => {
            state.isLoading = false;
            state.bonosList = action.payload;
        },
        setSelectedBono: (state, action) => {
            state.selectedBono = action.payload;
        },
        updateBonosList: (state, action) => {
            state.bonosList = action.payload;
        },
        setError: (state, action) => {
            const message = action.payload.message;
            state.statusCode = action.payload.status;
            state.isErrorMessage = message;
        },
    }
});

export const {
    startLoadingBonos,
    setAllBonos,
    setTotalPages,
    setError,
    setSelectedBono,
    updateBonosList
} = bonosSlice.actions;