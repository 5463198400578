import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management:
const Bonos = Loader(lazy(() => import('src/content/management/Bonos')));

const managementRoutes = [
  {
    path: '',
    element: <Navigate to="bonos" replace />
  },
  {
    path: 'bonos',
    children: [
      {
        path: '',
        element: <Bonos />
      }
    ]
  }
];

export default managementRoutes;
