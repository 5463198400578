import { Box, Card, Typography, Container, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import esJSON from 'src/i18n/translations/es';
import { useTranslation } from 'react-i18next';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function LoginBasic() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = () => {
      console.log("ERROR");
  };
  return (
    <>
      <Helmet>
        <title>{t(esJSON.Login)}</title>
      </Helmet>
        <MainContent>
            <TopWrapper>
                <Container maxWidth="sm" sx={{ alignSelf: 'center' }}>
                  <Box
                      sx={{
                        margin: 'auto',
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'flex'
                      }}
                    >
                      <img src={require('./logo.png')} alt="logotipo-Two" />
                  </Box>
                  <Card
                    sx={{
                      mt: 3,
                      px: 4,
                      pt: 5,
                      pb: 3,
                      mb: 7
                    }}
                  >
                  <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
                    <Typography
                      variant="h2"
                      sx={{
                        mb: 1
                      }}
                    >
                      {t(esJSON.Login)}
                    </Typography>
                  </Box>
                    {method === 'Firebase' && <FirebaseAuthLogin />}
                  </Card>
                </Container>
            </TopWrapper>
        </MainContent>
    </>
  );
}

export default LoginBasic;
