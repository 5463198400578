import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Login
const Login = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Basic'))
);


const baseRoutes = [
  {
    path: '/',
    element: <Login />
  }
];

export default baseRoutes;
