import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
  styled
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useState } from 'react';
import firebase from 'src/utils/firebase';
import { useNavigate } from 'react-router-dom';
import { fetchUserByAuthID } from 'src/services/auth.service';
import esJSON from 'src/i18n/translations/es';
import { useTranslation } from 'react-i18next';

const ImgWrapper = styled('img')(
  ({ theme }) => `
    margin-right: ${theme.spacing(1)};
`
);

function LoginFirebaseAuth() {
  const { signInWithGoogle, error, cleanError, isAuthenticated } = useAuth() as any;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t }: { t: any } = useTranslation();

  useEffect(() => {
    let isMounted = true;

    if (error.state) {
      const timer = setTimeout(() => {
        if (isMounted) {
          cleanError();
        }
      }, 3000);

      return () => {
        clearTimeout(timer);
        isMounted = false;
      };
    }

    return () => {
      isMounted = false;
    };
  }, [error.state, cleanError]);

  const handleGoogleClick = async () => {
    try {
      setLoading(true);
      

      await signInWithGoogle();
      try {
        const response = await fetchUserByAuthID();

        if (response.status === 200) {
          navigate('/dashboard/management/bonos');
        }
      } catch (error) {
        console.error('Error en la validación:', error);
      } finally {
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && isAuthenticated === true ? ( 
        <Box sx={{ display: 'flex', justifyContent: 'center'/* , paddingTop: 8  */}}>
          <CircularProgress />
        </Box>
      ) : (
        <Button
          fullWidth
          onClick={handleGoogleClick}
          size="large"
          variant="outlined"
        >
          <ImgWrapper alt="Google" src="/static/images/logo/google.svg" />
          {t(esJSON['Sign in with Google'])}
        </Button>
      )}
      <Box
        alignItems="center"
        display={{ xs: 'flex', md: 'flex' }}
        justifyContent="center"
        paddingTop={2}
        paddingBottom={1}
      >
        {(Boolean(error.submit) || error.state) && (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">
              {error.msg || errorMessage} — <strong>intenta nuevamente!</strong>
            </Alert>
          </Stack>
        )}
      </Box>
    </>
  );
}

export default LoginFirebaseAuth;

