import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { User } from 'src/models/user';
import firebase from 'src/utils/firebase';
import PropTypes from 'prop-types';
import { fetchUserByAuthID } from 'src/services/user.service';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  error: {
    state: boolean;
    msg: string;
  };
}

interface AuthContextValue extends AuthState {
  method: 'Firebase';
  createUserWithEmailAndPassword: (
    // eslint-disable-next-line no-unused-vars
    email: string,
    // eslint-disable-next-line no-unused-vars
    password: string
  ) => Promise<any>;
  // eslint-disable-next-line no-unused-vars
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  logout: () => Promise<void>;
  cleanError: () => any;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    error?: {
      state: boolean;
      msg: string;
    };
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: {
    state: false,
    msg: ''
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user, error } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      ...(error && { error })
    };
  }

  return state;
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Firebase',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  cleanError: () => {}
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (userFirebase) => {
      if (userFirebase) {
        try {
          const res = await fetchUserByAuthID();
          const userBack = res.data.data;
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                user: {
                  email: userFirebase.email,
                  photoURL: userFirebase.photoURL
                },
                error: {
                  state: false,
                  msg: ''
                }
              }
            });
        } catch (err) {
            console.log("Error in fetchUserByAuthID:", err.message);
            await logout();
              if (err.response && err.response.data && err.response.data.message) {
                const errorMessage = err.response.data.message;
                console.log("Detailed error message:", errorMessage);
                dispatch({
                  type: 'AUTH_STATE_CHANGED',
                  payload: {
                    isAuthenticated: false,
                    user: null,
                    error: {
                      state: true,
                      msg: errorMessage || 'Ocurrió un error inesperado'
                    }
                  }
        });
      } else {
            console.log("Error in fetchUserByAuthID:", err);
              dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                  isAuthenticated: false,
                  user: null,
                  error: {
                    state: true,
                    msg: err.message || 'Ocurrió un error inesperado'
                  }
                }
              });
      }}
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null,
            error: {
              state: false,
              msg: ''
            }
          }
        });
    }
  });
  
}, []);
  
  
  const logout = (): Promise<void> => {
    return firebase.auth().signOut();
  };
  

  const signInWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<any> => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = async (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();
    
    provider.setCustomParameters({ prompt: 'select_account' });
  
    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const cleanError = () => {
    dispatch({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        isAuthenticated: false,
        user: null,
        error: {
          state: false,
          msg: ''
        }
      }
    });
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Firebase',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout,
        cleanError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
