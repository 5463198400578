// let __DEV__ = true;
// import BaseConfig from './config.base';
// import ProdConfig from './config.prod';
// import DevConfig from './config.dev';

// let ExtraConfig = ProdConfig;

// if (__DEV__) {
//   ExtraConfig = DevConfig;
// }

// const Config = { ...BaseConfig, ...ExtraConfig };
const Config = {
  API_URL: process.env.REACT_APP_API_URL
}
export default Config;
