import axios from 'axios';
import firebase from 'src/utils/firebase';

export const apiConfig = axios.create({
	baseURL: process.env.REACT_APP_API_URL
})

apiConfig.interceptors.request.use(async (config) => {
	const user = firebase.auth().currentUser;
	if (user) {
		const token = await user.getIdToken();
		config.headers['Authorization'] = `Bearer ${token}`;
	}
	return config;
});